body {
  background-color: #EDF4F9 !important;
  font-family: 'DINPro' !important;
}
@font-face {
  font-family: 'DINPro'; /*a name to be used later*/
  src: url('/assets/DINPro.otf'); /*URL to font*/
}

.navbar-laravel {
  box-shadow: 0 2px 4px white !important;
}

.btn {
  background-color: #005dab !important;
  color: white !important;
  border-color: #bdcdea !important;
}

h2 {
  color: #005DAB !important;
}

a {
  color: #494949;
}

.swal2-actions {
  width: 250px !important;
}

.btn_White {
  background-color: white !important;
  color: #005DAB !important;
  border-color: #005DAB !important;
}

.panel {
  border: 1px solid white !important;
  background-color: white !important;
  border-radius: 25px;
}

label {
  font-weight: bold;
  color: #005DAB !important;
}

p {
  font-size: small;
}

@media print {
  #printContainer > .printedPage {
    width: 99% !important;
  }
}